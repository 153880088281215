import hero1 from "../../raitagyana-images/0FF86A27-2A7E-4F51-B46D-A24C46970AEB (1).jpeg";
import hero2 from "../../raitagyana-images/46FF1817-1672-4C36-8677-78D1DB642C7E.jpeg";
// import hero3 from "../../raitagyana-images/9b7e5070-c2b2-4634-bdf2-22ca5752408c.jpeg";
import hero3 from "../../images/hero3.jpg";

export const sliderData = [
  {
    image: hero1,
    heading: "Indian Agriculture",
    desc: "Approximately 42% of Indians rely on agriculture, contributing 18% to the economy. Despite being the world's second-largest agricultural land, farming struggles, employing half the population.",
  },
  {
    image: hero2,
    heading: "Indian Agriculture landscape",
    desc: "Approximately 42% of the Indian population is dependent for livelihood and approximately 18% is contributed to the Indian Economy",
  },
  {
    image: hero3,
    heading: "Efficient Farming: Cost Reduction & Seed Optimization",
    desc: "Maximize farming efficiency with reduced input costs by 15% and optimal seed observation exceeding 80% for improved yields.",
  },
];
