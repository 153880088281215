import one from "../../raitagyana-images/a3103315-f502-4d07-82b6-b51696d7a7e9.jpeg";
import two from "../../raitagyana-images/90d930ba-5d41-4f53-b773-43b4196f333e (2).jpeg";
import three from "../../raitagyana-images/_13E2E30B-6AC3-4BAB-8F18-40E10E1B7889.jpeg";
import fourth from "../../raitagyana-images/_h1-banner01.jpeg";
import fifth from "../../raitagyana-images/1e0abebc-ada1-4ad8-810e-1ddf1ac3f450.jpeg";
import sixth from "../../raitagyana-images/IMG_1353.jpeg";
import seven from "../../raitagyana-images/FEDE856C-A7A2-42EB-B841-EB9497B8EFB3.jpeg";
import eight from "../../raitagyana-images/D3AAA0FF-40C3-470F-AA66-D81AA03DA7F7.jpeg";
import nine from "../../raitagyana-images/2DC70582-8373-4231-BBD7-1096FE4F10D4.jpeg";
import ten from "../../raitagyana-images/6.jpeg";
import eleven from "../../raitagyana-images/10.jpeg";
import twelve from "../../raitagyana-images/12.jpeg";
export const media = [
  {
    id: 1,
    type: "image",
    url: one,
  },
  {
    id: 2,
    type: "image",
    url: two,
  },
  {
    id: 3,
    type: "image",
    url: three,
  },
  {
    id: 4,
    type: "image",
    url: fourth,
  },
  {
    id: 5,
    type: "image",
    url: fifth,
  },
  {
    id: 6,
    type: "image",
    url: sixth,
  },
  {
    id: 7,
    type: "image",
    url: seven,
  },
  {
    id: 8,
    type: "image",
    url: eight,
  },
  {
    id: 9,
    type: "image",
    url: nine,
  },
  {
    id: 10,
    type: "image",
    url: ten,
  },
  {
    id: 11,
    type: "image",
    url: eleven,
  },
  {
    id: 12,
    type: "image",
    url: twelve,
  },
];
