import React, { useState, useEffect } from "react";
import secImg from "../../images/TriasseaIcon.png";
import { serviceData } from "./serviceData";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import "./service.css";
import serviceimg from "../../images/servicepng.png";

const Service = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlidesPerView = () => {
    if (screenWidth <= 400) {
      return 1; // For mobile screens
    } else if (screenWidth <= 540) {
      return 2; // For tablet screens
    } else if (screenWidth <= 900) {
      return 3; // For tablet screens
    } else {
      return 4; // For laptop screens
    }
  };

  return (
    <section className="service-div  mt-20 py-16 ">
      <div className=" ">
        <div className="flex justify-center ml-6 items-center gap-2">
          <img className="w-8" src={secImg} alt="" />
          <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
          Our Mission
          </h3>
        </div>
        <h1 className="text-[24px] text-center opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
          What We Provide
        </h1>
      </div>

      <div className="px-6">
        <Swiper
          slidesPerView={getSlidesPerView()}
          spaceBetween={30}
          modules={[Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
        >
          {serviceData.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className=" text-black transform transition-all ease-in-out duration-300  ">
                <div className="overflow-hidden rounded-lg ">
                  <img
                    src={slide.imageUrl}
                    alt={slide.altText}
                    className="w-full h-full object-cover hover:scale-110 "
                  />
                </div>
                <h2 className="text-xl 2xl:text-2xl  font-semibold ">
                  {slide.linkText}
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Service;
