const data = [
  {
    title: "Basic Inputs",
    description:
      "Secure discounted supplies from trusted agricultural wholesalers.",
    imageSrc:
      "https://demo2.themelexus.com/farmor/wp-content/uploads/2023/09/process-2.jpg",
  },
  {
    title: "Technical Training",
    description:
      "Provide farmers with essential agricultural skills and knowledge.",
    imageSrc:
      "https://demo2.themelexus.com/farmor/wp-content/uploads/2023/09/process-1.jpg",
  },
  {
    title: "Equipment leasing",
    description:
      "Enable access to farming machinery through cost-effective leasing options.",
    imageSrc:
      "https://demo2.themelexus.com/farmor/wp-content/uploads/2023/09/process-3.jpg",
  },
  {
    title: "Storage Facility",
    description:
      "Offer affordable storage solutions for harvested crops.",
    imageSrc:
      "https://demo2.themelexus.com/farmor/wp-content/uploads/2023/09/process-4.jpg",
  },
];

export default data;
