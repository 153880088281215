import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { data, successStoriesData } from "./impactdata";
import CompoHeader from "../common/CompoHeader";
import impact from "../../images/impacts.jpg";
import secImg from "../../images/TriasseaIcon.png";
import "./Impact.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { FaCirclePlus, FaPercent } from "react-icons/fa6";

const countersData = [
  { value: 600, label: "Happy Clients" },
  { value: 450, label: "Staff Members" },
  { value: 40, label: "Countries" },
];

const Impact = () => {
  const [counterOn, seCounterOn] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <section>
        <CompoHeader name="Impact" image={impact} />
      </section>
      <div className="mt-10 md:ml-1 lg:ml-2 ">
        <div className="flex justify-center items-center gap-2">
          <img className="w-8" src={secImg} alt="" />
          <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
            Our Impact
          </h3>
        </div>
        <h1 className="text-[24px] text-center mb-10 ml-6 opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
          The impact it has can be huge
        </h1>
      </div>
      <section className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <div className="sm:col-span-1">
          {data.map((item, index) => (
            <div key={index} className="sm:col-span-1">
              <h3 className="ml-6 text-2xl md:text-3xl lg:text-4xl antialiased font-sans font-semibold opacity-80">
                {item.title}
              </h3>

              <p className="tagP text-gray-500 pt-4 ml-3 sm:ml-5 md:ml-5 lg:ml-5 xl:ml-5 mb-3 sm:mb-5 md:mb-8 lg:mb-5 xl:mb-8">
                {item.content}
              </p>
            </div>
          ))}
        </div>

        <div className="sm:col-span-1 flex justify-center">
          <img
            className="last-img rounded-lg rounded-tr-lg rounded-br-lg cursor-pointer w-full mx-auto sm:mx-0"
            src="https://images.squarespace-cdn.com/content/v1/5df1453b1180e26a82cf7100/a387cf28-3b53-4f58-883d-042f482160ed/Tree+Planting+1.jpg?format=1500w"
            alt=""
          />
        </div>
      </section>
      <section className="mt-5 mr-5">
        <h3 className="ml-6 text-2xl md:text-3xl lg:text-4xl antialiased font-sans font-semibold opacity-80">
          The Solution
        </h3>

        <p className="tagP mt-4 text-gray-500 ml-3 sm:ml-5 md:ml-5 lg:ml-5 xl:ml-5 mb-3 sm:mb-5 md:mb-8 lg:mb-5 xl:mb-8">
          Our Impact Earth initiatives include solar power, rainwater
          harvesting, tree-planting, and educational upcycling to rethink the
          use of trash in our school environment. Each of our students
          participates in environmental activities culminating in an
          Environmental Awareness Day each term.
        </p>
      </section>

      <section
        className="mainDivInc pt-20 bg-gray-100  w-[95vw] max-w-[1725px] h-[200px]"
        style={{ borderRadius: "8px" }}
      >
        <div className="flex flex-col md:flex-row justify-around items-center gap-8">
          {/* <img src={secImgVid} alt="" width="250px" className="ml-[-4.2%]" /> */}
          {countersData.map((item, index) => (
            <ScrollTrigger
              key={index}
              onEnter={() => seCounterOn(true)}
              onExit={() => seCounterOn(false)}
            >
              <div className="flex flex-col min-w-[190px] items-center p-4 bg-white rounded-lg shadow-md">
                <div className="flex">
                  <h1 className="text-4xl text-gray-800 opacity-80 font-extrabold">
                    ~
                    {counterOn && (
                      <CountUp start={0} end={item.value} duration={3} />
                    )}
                  </h1>
                  {/* <FaCirclePlus className="text-yellow-400 text-4xl" /> */}
                  <FaPercent className="text-gray-400 text-4xl" />
                </div>
                <p className="text-lg font-semibold text-gray-700">
                  {item.label}
                </p>
              </div>
            </ScrollTrigger>
          ))}
        </div>
      </section>

      <section className="bg-gray-100 py-16" id="success-stories">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-[24px] mb-10 ml-6 opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
              Success Stories
            </h1>
            <p className="text-gray-600">
              Inspiring tales of farmers who have transformed their lives and
              communities through sustainable agriculture.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {successStoriesData.map((story, index) => (
              <div
                key={index}
                className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300 mb-8"
              >
                <img
                  src={story.imageSrc}
                  alt=""
                  className="w-full h-60 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{story.title}</h3>
                  <p className="text-gray-700 mb-4">{story.description}</p>
                  <div className="flex items-center">
                    {story.icon}
                    <span className="text-gray-600">{story.category}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Impact;
