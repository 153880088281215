import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import contactUsImage from "../../images/contactus1.jpg";
// import contactUsImage from "../../raitagyana-images/0FF86A27-2A7E-4F51-B46D-A24C46970AEB (1).jpeg";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import secImg from "../../images/TriasseaIcon.png";
import "./ContactUsPage.css";
import CompoHeader from "../common/CompoHeader";

const ContactUsPage = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <CompoHeader name="contact" image={contactUsImage} />

      <div className="main-box grid md:grid-cols-3 mt-[6%] mb-[6%] gap-4">
        <div
          className="idenity grid grid-cols-2 "
          style={{
            backgroundColor: "rgb(248, 240, 232)",
          }}
        >
          <div className="flex justify-center items-center mr-20">
            <IoLocationOutline
              className="text-yellow-400"
              style={{ fontSize: "50px" }}
            />
          </div>
          <div className="contact grid grid-cols-1 justify-center items-center">
            <p>Our Location</p>
            <h1>Rampure Colony, Opp Pharmacy College, BVB road Bidar 585401</h1>
          </div>
        </div>
        <div
          className="idenity grid grid-cols-2 "
          style={{
            backgroundColor: "rgb(248, 240, 232)",
          }}
        >
          <div className="flex justify-center items-center mr-20">
            <MdOutlinePhoneInTalk
              className="text-red-500"
              style={{ fontSize: "50px" }}
            />
          </div>
          <div className="contact grid grid-cols-1 justify-center items-center">
            <p>Contact Numbers</p>
            <h1>+91-9611050055</h1>
            <h1>+91-8951734819</h1>
          </div>
        </div>
        <div
          className="idenity grid grid-cols-2 "
          style={{
            backgroundColor: "rgb(202, 229, 247)",
          }}
        >
          <div className="flex justify-center items-center mr-20">
            <MdOutlineMailOutline
              className="text-pink-500"
              style={{ fontSize: "50px" }}
            />
          </div>
          <div className="contact grid grid-cols-1  justify-center items-center">
            <p>Email Address</p>
            <h1 className="pb-[30px]">support@raitagyana.org</h1>
          </div>
        </div>
      </div>

      <section className="grid grid-cols-1 mb-10 mt-20 mr-5 md:grid-cols-2 lg:grid-cols-2 gap-8">
        <form onSubmit={handleSubmit} className="ml-6 mb-4">
          <div className="mb-10 md:ml-1 lg:ml-2 ">
            <div className="flex justify-start items-start gap-2">
              <img className="w-8" src={secImg} alt="" />
              <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
                Contact With Us
              </h3>
            </div>
            <h1 className="text-[24px]  mb-10 opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
              Ready to work with us
            </h1>
          </div>

          <div className="grid grid-cols-2 justify-center items-center">
            <label
              htmlFor="name"
              className="block text-sm ml-2 font-medium text-gray-700"
            >
              Name
            </label>

            <label
              htmlFor="email"
              className="block text-sm ml-4 font-medium text-gray-700"
            >
              Email Address
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <input
              type="text"
              id="name"
              name="name"
              required={true}
              placeholder="Your Full Name"
              className="mt-1 p-2 border border-gray-300 rounded-md"
            />
            <input
              type="email"
              id="email"
              name="email"
              required={true}
              placeholder="email"
              className="mt-1 p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="grid grid-cols-2 justify-center items-center">
            <label
              htmlFor="phone"
              className="block mt-4 ml-2 text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>

            <label
              htmlFor="category"
              className="block mt-4 ml-4 text-sm font-medium text-gray-700"
            >
              Select Service
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <input
              type="tel"
              id="phone"
              name="phone"
              required={true}
              placeholder="Phone"
              className="mt-1 p-2 border border-gray-300 rounded-md"
            />
            <select
              id="category"
              name="category"
              required={true}
              className="mt-1 p-2 border border-gray-300 rounded-md"
            >
              <option value="">Service</option>
              <option value="agriculture">Agriculture Products</option>
              <option value="fresh">Fresh Vegetable</option>
              <option value="dairy">Dairy Products</option>
              <option value="organic">Organic Products</option>
              <option value="farm">Farm House</option>
              <option value="harvest">Harvest Incentive</option>
              <option value="vegetable">Vegetable Firms</option>
              <option value="water">Water Management</option>
            </select>
          </div>

          <label
            htmlFor="message"
            className="block mt-4 ml-2 text-sm font-medium text-gray-700"
          >
            Additional Information
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            className="mt-1 w-full h-[200px] border border-gray-300 rounded-md"
            style={{ resize: "none" }}
          ></textarea>
          <div className="mt-4">
            <input
              type="checkbox"
              id="saveInfo"
              name="saveInfo"
              required={true}
              className="mr-2"
            />
            <label htmlFor="saveInfo" className="text-sm text-gray-700">
              Save my name and email in this browser for the next time I
              comment.
            </label>
          </div>
          <button type="submit" className="ContactsubmitButton">
            submit
          </button>
        </form>

        <div className="last-imgDiv mt-8 md:mt-0 lg:ml-10 lg:mt-5">
          <img
            className="final-img rounded-md cursor-pointer w-[600px] h-[600px]"
            src="https://demo2.themelexus.com/farmor/wp-content/uploads/2023/10/Contact-1.jpg"
            alt=""
          />
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
