const dataArray = [
  [
    {
      id: 1,
      tag: "What is Raitagyana's mission?",
      description:
        "Raitagyana's mission is to empower marginalized farmers and foster sustainable agriculture in rural India by providing essential resources, training, and market access.",
    },
    {
      id: 2,
      tag: "How does Raitagyana empower farmers?",
      description:
        "Raitagyana empowers farmers by supplying high-quality inputs, offering comprehensive training programs, establishing direct market linkages, and promoting environmentally friendly farming methods.",
    },
    {
      id: 3,
      tag: "What are the key initiatives of Raitagyana?",
      description:
        "Raitagyana's key initiatives include providing quality agricultural inputs, offering training on sustainable farming practices, establishing direct market connections, promoting environmental sustainability, and creating income opportunities for farmers.",
    },
    {
      id: 4,
      tag: "What is the ultimate goal of Raitagyana?",
      description:
        "Raitagyana aims to create a more resilient, equitable, and sustainable agricultural ecosystem for farmers and communities in rural India, fostering economic growth and environmental preservation.",
    }
  ],
];

export default dataArray;