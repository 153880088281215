import React from "react";
import chooseUsBack from "../../images/choose_us_back.png";
// import chooseUsAboveImg from "../../images/chosse_above_image.jpg";
import chooseusRightBack from "../../images/choose_us_right_back.png";
import secImg from "../../images/TriasseaIcon.png";
import { RiBusFill } from "react-icons/ri";
import { GiThreeLeaves } from "react-icons/gi";
import { LiaTruckMonsterSolid } from "react-icons/lia";
import { CiDeliveryTruck } from "react-icons/ci";
import { GrDropbox } from "react-icons/gr";
import "./choose.css";

import chooseUsAboveImg from "../../raitagyana-images/86A77CC9-2010-49E9-9B45-783937415278.jpeg";

const data = [
  {
    icon: (
      <GiThreeLeaves className="cursor-pointer text-5xl md:text-4xl lg:text-7xl hover:text-yellow-600" />
    ),
    title: "Awareness Events",
    description: "Organized awareness events at 8 locations.",
  },
  {
    icon: (
      <LiaTruckMonsterSolid className="cursor-pointer text-5xl md:text-4xl lg:text-7xl hover:text-yellow-600" />
    ),
    title: "Interviewed 300+ Farmers",
    description: "We have Interviewed 300+ Farmers.",
  },
  {
    icon: (
      <CiDeliveryTruck className="cursor-pointer text-5xl md:text-4xl lg:text-7xl hover:text-yellow-600" />
    ),
    title: "Financed & distribution",
    description: "Financed & distributed inputs to 40 farmers",
  },
  {
    icon: (
      <GrDropbox className="cursor-pointer text-5xl md:text-4xl lg:text-7xl hover:text-yellow-600" />
    ),
    title: "Training Sessions",
    description: "Conducted 9 trainings with 1000+ farmers",
  },
];

const Choose = () => {
  return (
    <div className="chooseMainDiv  flex justify-between  gap-8 md:h-[690px] lg:h-[830px]  ">
      {/* left  div  */}
      <div className="relative chooseLeftDiv  h-full max-w-[65%] min-w-[52%] ">
        <img
          src={chooseUsBack}
          alt=""
          className="w-full h-full object-contain mt-16"
        />

        <div className="h-full w-[65%] bg-yellow-500 opacity-60 absolute top-0 left-0 rounded-lg z-1 "></div>

        <img
          src={chooseUsAboveImg}
          alt=""
          className="w-[77%] h-[80%] md:w-[89%] lg:w-[77%] md:h-[85%] lg:h-[80%] absolute right-0 bottom-0 rounded-lg object-cover "
        />
      </div>

      {/* right div */}
      <div className=" text-center md:text-start max-h-max relative top-0 left-0 w-full  ">
        <img
          src={chooseusRightBack}
          alt=""
          className="w-full h-[300px]  absolute bottom-0 right-0 z-10"
        />

        <div className="absolute z-4  pr-5  bottom-0 w-full  p-[10px] md:p-[14px] lg:p-[0] lg:pr-5 h-[92%] md:h-[95%] lg:h-[90%] 2xl:h-[86%]  bg-white opacity-80">
          <div className="flex justify-start items-center gap-2">
            <img className="w-8" src={secImg} alt="" />
            <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
              Why Choose Us
            </h3>
          </div>

          <h1 className="text-[24px] md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
            We Are Different From Other Farming
          </h1>

          <p className="text-green-900 text-xl font-medium  md:text-[22px] mt-6 lg:mt-7">
            We have 15 years of agriculture & eco farming experience globaly,
            work with professionals
          </p>

          <p className="text-[.9rem] md:text-[17px] mt-6 text-gray-500 lg:mt-7   opacity-90  leading-[1.3rem] lg:leading-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua dui at
            quam tincidunt sagittis.
          </p>

          <div className=" grid_div grid mt-3  grid-cols-1 md:grid-cols-2 gap-x-6   gap-y-6 md:gap-x-12 md:gap-y-12 lg:gap-y-10 lg:gap-x-5    border-b border-gray-400 py-5 ">
            {data.map((elm) => (
              <div className=" flex flex-col md:flex-row  w-full gap-5 items-center justify-center m-auto">
                {elm.icon}

                <div className="text-center md:text-start">
                  <h1 className="text-xl md:text-lg lg:text-[18px] cursor-pointer hover:text-yellow-600 font-medium">
                    {elm.title}
                  </h1>
                  <p className="text-[14.2px] md:text-[1rem] lg:text-[1rem] mt-[.4rem] font-light text-gray-800 ">
                    {elm.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* <button className="px-5 py-3 text-white  bg-green-800 cursor-pointer rounded-md   chooseUsButton">
            Get in touch
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Choose;
