import React from "react";
import "./Footer.css";

import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineYoutube,
  AiOutlineFacebook,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Subscribe from "./Subscribe";
function Footer() {
  const navigate = useNavigate();
  return (
    <div>
      <footer className="footer">
        <Subscribe />
        <div className="footer__addr pl-[4.5%]">
          <h1 className="footer__logo">Raitagyana</h1>

          <h2>Address</h2>

          <address>
            Rampure Colony,
            <br />
            Opp Pharmacy College, BVB road Bidar-585401
            <br />
            <div className="flex gap-2 p-4 cursor-pointer">
              <a href="#" target="_blank">
                <AiOutlineWhatsApp className="socialmedia-icons" />
              </a>

              <a href="#" target="_blank">
                <AiOutlineInstagram className="socialmedia-icons" />
              </a>
              <a href="#" target="_blank">
                <AiOutlineMail className="socialmedia-icons" />
              </a>
              <a href="#" target="_blank">
                <AiOutlineFacebook className="socialmedia-icons" />
              </a>
              <a href="#" target="_blank">
                <AiOutlineYoutube className="socialmedia-icons" />
              </a>
            </div>
          </address>
        </div>

        <ul className="footer__nav">
          <li className="nav__item">
            <h2 className="nav__title">Contact No.</h2>

            <ul className="nav__ul">
              <li>
                <a href="#">+91-9611050055</a>
              </li>

              <li>
                <a href="#">+91-8951734819 </a>
              </li>
            </ul>
          </li>

          <li className="nav__item nav__item--extra">
            <Link to={"/about"} className="nav__title">
              About Agriculture
            </Link>

            <ul className="nav__ul nav__ul--extra">
              <li>
                <Link to={"/"}>Our Farming Mission & Vision</Link>
              </li>

              <li>
                <Link to={"/"}>Expert Agriculturists</Link>
              </li>

              <li>
                <Link to={"/contactUs"}>Contact Our Farm</Link>
              </li>
            </ul>
          </li>

          <li className="nav__item">
            <h2 className="nav__title">Agricultural Legal</h2>

            <ul className="nav__ul">
              <li>
                <a href="#">Farm Privacy Policy</a>
              </li>

              <li>
                <a href="#">Terms of Agricultural Use</a>
              </li>

              <li>
                <a href="#">Farm Sitemap</a>
              </li>
            </ul>
          </li>
        </ul>

        <div className="legal">
          <p>&copy; 2024 Raitagyana. All rights reserved.</p>

          <div className="legal__links">
            <span>
              Cultivated with <span className="heart">♥</span> to build a strong
              community.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
