import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dataArray from "./FaqData";
import { FaSunPlantWilt } from "react-icons/fa6";
import "./faq.css";
// import faqimage from "../../images/faqp.jpg";
import faqimage from "../../raitagyana-images/3F0C5B8B-2780-4F36-9295-08B0CFC24718.jpeg";
import secImg from "../../images/TriasseaIcon.png";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const FAQ = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [activeArray, setActiveArry] = useState(dataArray[0]);

  const [activeIndex, setActiveIndex] = useState(null);

  const clickHeader = (event, index) => {
    event.stopPropagation();
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      <section className="relative grid grid-cols-1 mb-10 mt-20 mr-5 gap-8 md:grid-cols-2 lg:grid-cols-2">
        <div className="leftDivFaq">
          <div>
            <img className="w-full object-cover" src={faqimage} alt="" />
          </div>

          <div className="samllDivFaq absolute bg-green-700 rounded-[3px]">
            <div className="">
              <FaSunPlantWilt className="text-white pl-[7%] mt-[4%]" />
            </div>
            <p className="tag1 text-white  mt-[4%] pl-[7%]">Our Value</p>
            <p className="tag2 text-white  mt-[4%] pl-[7%] pr-[7%]">
            At Raitagyana, we are driven by a singular mission: to empower marginalized farmers and foster sustainable agriculture in rural India.
            </p>
          </div>
        </div>

        <div className="pt-[12%] pr-[3%] md:mt-0 lg:ml-10 lg:mt-5 ml-6 text-black">
          <div className="mb-6 md:ml-1 lg:ml-2 ">
            <div className="flex justify-start items-center gap-2">
              <img className="w-8" src={secImg} alt="" />
              <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
                Find Your Answers
              </h3>
            </div>
            <h1 className="text-5xl mt-4 font-semibold opacity-80">
              Do You Have Any Questions ?
            </h1>
            <p className="mt-7 text-gray-600">
            Serves as an invitation for individuals to seek clarification or additional information about Raitagyana's mission and initiatives. It prompts them to engage further by directing them to the FAQ section below for answers to common inquiries.
            </p>
          </div>
          <div
            id="accordion"
            className="w-[100%]  rounded-lg overflow-hidden flex flex-col gap-[1.5px] shadow-xl"
          >
            {activeArray.map((item, index) => (
              <div
                key={index}
                className={`item ${index === activeIndex ? "active" : ""}`}
              >
                <div
                  onClick={(e) => clickHeader(e, index)}
                  className="header p-6 text-lg rounded-lg hover:text-white hover:bg-green-600  flex justify-between items-center cursor-pointer shadow-md"
                >
                  <div>
                    <div className="faq-tag" style={{ letterSpacing: "1.3px" }}>
                      {item.tag}
                    </div>
                  </div>
                  <IoIosArrowUp className="w-6 h-6 activeIcon" />
                  <IoIosArrowDown className="w-6 h-6 inactiveIcon" />
                </div>
                <div className="faq-content text-lg text-grey-700 transition-all duration-500">
                  <p className="faq-descreption">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
