import React, { useState } from "react";
import { FaFacebook, FaEnvelope, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./TeamMembers.css";

import mahadev from "../../raitagyana-images/a4eeaf57-0ff8-448e-8f30-3c0a4249d6bf.png";
// import omkarjypuria from "../../assest/team2.jpg";
// import Gadadharmartha from "../../assest/team3.jpg";
import sushant from "../../raitagyana-images/8ea424ef-86e6-4fe8-b030-d41fdcc6a929.jpeg";
// import Amitkumarpatel from "../../assest/team4.jpg";
import annaroa from "../../raitagyana-images/4fe73e5d-9fc0-48c7-8ce9-b3a917a85353.png";
import nikita from "../../raitagyana-images/f1e11e07-8284-4a1a-8627-d876e778b85c.png";

import secImg from "../../images/TriasseaIcon.png";
const teamData = [
    {
      name: "NIKITA VARDIPARTI",
      role: "Co-founder, Executive Director",
      email: "nikita@raitagyana.org",
      imageSrc: nikita,
    },
    {
      name: "SUSHANT SWAMI",
      role: "Co-founder, Director, Operations & finance",
      email: "sushant@raitagyana.org",
      imageSrc: sushant,
    },
    {
      name: "MAHADEV S.",
      role: "Chief Program Officer",
      email: "mahadev@raitagyana.org",
      imageSrc: mahadev,
    },
    {
      name: "ANNARAO PATIL",
      role: "Chief Operations Officer",
      email: "annaroa@raitagyana.org",
      imageSrc: annaroa,
    },
  
  ];

const Team = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseOver = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="pt-10 ">
      <div className="flex justify-center ml-6 items-center gap-2 2xl:mb-10">
        <img className="w-8" src={secImg} alt="" />
        <h3 className="text-xl md:text-2xl lg:text-3xl  antialiased font-sans font-semibold opacity-80">
          Our Team
        </h3>
      </div>

      <div className="px-8  pt-10 pb-16 2xl:py-4  xl:px-5 max-h-max place-content-center grid sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-screen-xl mx-auto teams">
        {teamData.map((team, index) => (
          <div
            key={index}
            className={`${
              activeIndex === index ? "active" : ""
            } bg-white shadow-lg border-b-4 border-transparent group transition ease-in-out delay-150 hover:border-green-800 duration-300 cursor-pointer`}
            onMouseOver={() => handleMouseOver(index)}
          >
            <div className="relative">
              <img
                src={team.imageSrc}
                alt={team.name}
                className="w-full object-cover"
              />
              <div className="overlay absolute bg-green-500 bg-opacity-0 group-hover:bg-opacity-70 grid place-items-center transition-colors ease-in delay-100">
                <ul className="logos w-full opacity-0 transition-opacity group-hover:opacity-100 ease-in-out delay-150 flex flex-wrap gap-1 text-xl place-items-center transform rotate-45">
                  <li className="icon-box1 w-11 h-11 bg-gray-700 flex items-center justify-center cursor-pointer hover:bg-gray-600">
                    <a href="#">
                      <FaFacebook className="text-white" />
                    </a>
                  </li>
                  <li className="icon-box2 w-11 h-11 bg-gray-700 flex items-center justify-center cursor-pointer hover:bg-gray-600">
                    <a href="#">
                      <FaEnvelope className="text-white" />
                    </a>
                  </li>
                  <li className="icon-box3 w-11 h-11 bg-gray-700 flex items-center justify-center cursor-pointer hover:bg-gray-600">
                    <a href="#">
                      <FaLinkedin className="text-white" />
                    </a>
                  </li>
                  <li className="icon-box4 w-11 h-11 bg-gray-700 flex items-center justify-center cursor-pointer hover:bg-gray-600">
                    <a href="#">
                      <FaTwitter className="text-white" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt-5 pb-7 px-5 text-center">
              <h2 className="text-xl font-semibold">{team.name}</h2>
              <span className="text-gray-500 capitalize inline-block mt-1 mb-4">
                {team.role}
              </span>
              <p className="text-gray-500">
                Email:{" "}
                <a href="#" className="font-medium group-hover:text-gray-800">
                  {team.email}
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
