import "tailwindcss/tailwind.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navber/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import ContanctUsPage from "./components/ContactUsPage/ContanctUsPage";

import Impact from "./components/Impact/Impact";

import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import Gallery from "./components/Gallery/Gallery";
 
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          

          <Route path="/contactUs" element={<ContanctUsPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/successstories" element={<Impact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
