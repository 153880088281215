import React, { useState, useEffect } from "react";
import aboutUsHeader from "../../images/AboutUs.jpg";

import CompoHeader from "../common/CompoHeader";
import About from "../About/About";
import secImg from "../../images/TriasseaIcon.png";

import bgImage from "../../images/aboutus-back.png";
import "swiper/css";

import { GiThreeLeaves } from "react-icons/gi";
import { LiaTruckMonsterSolid } from "react-icons/lia";
import { CiDeliveryTruck } from "react-icons/ci";
import { GrDropbox } from "react-icons/gr";

import "./AboutUsPage.css";
import AboutusBottom from "./AboutusBottom";

const AboutUsPage = () => {
  return (
    <div>
      <section>
        <CompoHeader name="About Us" image={aboutUsHeader} />
      </section>

      <section className="grid grid-cols-1 mb-10 mt-20 md:grid-cols-2 lg:grid-cols-2">
        <div>
          <div className="relative">
            <img src={bgImage} className="opacity-30" alt="" />

            <div className="absolute top-[10%] left-0 ml-4">
              <div className="mb-6 md:ml-1 lg:ml-2">
                <div className="flex justify-start items-start gap-2">
                  <img className="w-8" src={secImg} alt="" />
                  <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
                    Landmass holding basis
                  </h3>
                </div>
                <h1 className="text-[24px] mb-10 ml-6 opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
                  Categorization of Farmers
                </h1>
                <p className="my-1 text-lg text-gray-700">
                  Farmers categorized by land size: representing varying scales
                  of agricultural operations, from small subsistence farms to
                  large commercial enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p-[2%] grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 md:mt-0 lg:ml-10 lg:mt-5 ml-6 text-black">
          <div className="aboutus-grid m-2">
            <div className="aboutus-small flex flex-row mt-5 justify-between gap-40 text-5xl">
              <GiThreeLeaves className="symbol1" />
              <h1 className="">01</h1>
            </div>
            <div>
              <h1 className="aboutus-heading text-xl">Marginal Farmers</h1>
              <p className="text-gray-700 text-sm">
                Small landholding, typically less than 1 hectare.
              </p>
            </div>
          </div>
          <div className="aboutus-grid m-2">
            <div className="aboutus-small flex flex-row mt-5 justify-between gap-40 text-5xl">
              <LiaTruckMonsterSolid className="symbol1" />
              <h1 className="">02</h1>
            </div>
            <div>
              <h1 className="aboutus-heading text-xl">Small Farmers</h1>
              <p className="text-gray-700 text-sm">
                Moderate land size, ranging from 1 to 2 hectares.
              </p>
            </div>
          </div>
          <div className="aboutus-grid m-2">
            <div className="aboutus-small flex flex-row mt-5 justify-between gap-40 text-5xl">
              <CiDeliveryTruck className="symbol1" />
              <h1 className="">03</h1>
            </div>
            <div>
              <h1 className="aboutus-heading text-xl">Medium Farmers</h1>
              <p className="text-gray-700 text-sm">
                Substantial landholding, typically between 4 to 10 hectares.
              </p>
            </div>
          </div>
          <div className="aboutus-grid m-2">
            <div className="aboutus-small flex flex-row mt-5 justify-between gap-40 text-5xl">
              <GrDropbox className="symbol1" />
              <h1 className="">04</h1>
            </div>
            <div className="mt-4">
              <h1 className="aboutus-heading text-xl">Large Farmers</h1>
              <p className="text-gray-700 text-sm">
                Significant land size, exceeding 10 hectares.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-10">
        <About />
      </section>
      <AboutusBottom />
    </div>
  );
};

export default AboutUsPage;
