import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import secImg from "../../images/TriasseaIcon.png";
import "slick-carousel/slick/slick-theme.css";
import { GoStarFill } from "react-icons/go";
import "./testimonial.css";

import mainImage1 from "../../raitagyana-images/testimonial-face.png";
import mainImage2 from "../../raitagyana-images/h1-banner01.jpeg";


const testimonialData = [
  {
    description:
      "Thanks to Raitagyana, I can't believe I have access to these agri machines, previously exclusive to village heads; I never thought I could afford to rent them, and they've greatly eased my work.",
    imgurl: mainImage2,//"https://dummyimage.com/106x106",
    name: "Manik, Changler",
    profession: "Farmer",
    profileIcon: mainImage1,
    heading: 'Kudos'
  },
  {
    description:
      "Despite my husband's illness, I manage our fields alone, supported by Raitagyana. Their aid brought confidence and a new drainage system, resolving soil erosion issues. I've also learned proper spraying techniques.",
    imgurl: mainImage2,//"https://dummyimage.com/106x106",
    name: "Supriya, Ghodehipurga",
    profession: "Farmer",
    profileIcon: mainImage1,
    heading: 'Gratitude'
  },
  {
    description:
      "Raitagyana's training rescued our land from recurring pest attacks, sparing us from selling. Correct methods learned led to lush harvests akin to our father's time, two and a half decades ago, evoking memories of verdant fields.",
    imgurl: mainImage2,//"https://dummyimage.com/106x106",
    name: "Peerappa, Gadgi",
    profession: "Farmer",
    profileIcon: mainImage1,
    heading: 'Applause'
  }
];

const Testimonial = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 668);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Display two cards at a time initially
    slidesToScroll: 1,
    autoplay: true, // Autoplay enabled
    autoplaySpeed: 1000, // Autoplay speed in milliseconds (1 second)
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // Medium devices (tablets, 768px and up)
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640, // Small devices (phones, 576px and up)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  return (
    <section className="mainTestimonial text-gray-800 body-font mt-[10rem] max-w-[100%]   ">
      <div className="container px-5  mx-auto  ">
        <div className="mb-[2rem] md:ml-1 lg:ml-2  ">
          <div className="flex justify-center ml-6 items-center gap-2">
            <img className="w-8" src={secImg} alt="" />
            <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
              Testimonial
            </h3>
          </div>
        </div>
        <div className="w-full py-10 my-10  mb-10  overflow-hidden  h-[450px]">
          <Slider {...settings} className=" mx-auto max-w-[98%]">
            {testimonialData.map((elm, index) => (
              <div key={index} className="">
                <div
                  className={`h-full text-black p-8 rounded-lg ${
                    index % 2 === 0 ? "card-test" : "card-test2"
                  } shadow-lg`}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-b border-gray-400 mb-4">
                    <div className="flex flex-col  ">
                      <div className="flex gap-2 mb-1 items-center  ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="block w-5 h-5 text-green-900 mb-4"
                          viewBox="0 0 975.036 975.036"
                        >
                          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                        <GoStarFill size={17} className="text-yellow-500" />
                        <GoStarFill size={17} className="text-yellow-500" />
                        <GoStarFill size={17} className="text-yellow-500" />
                        <GoStarFill size={17} className="text-yellow-500" />
                        <GoStarFill size={17} className="text-yellow-500" />
                      </div>
                      <h3 className="text-black font-semibold text-2xl mt-1">
                        {elm.heading}
                      </h3>
                      <p className="leading-relaxed mb-6">{elm.description}</p>
                      <a href="#" className="inline-flex items-center ">
                        <img
                          alt="testimonial"
                          src={elm.profileIcon}
                          className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                        />
                        <span className="flex-grow flex flex-col pl-4">
                          <span className="title-font font-medium">
                            {elm.name}
                          </span>
                          <span className="text-orange-300 text-sm">
                            {elm.profession}
                          </span>
                        </span>
                      </a>
                    </div>
                    <img
                      src={elm.imgurl}//"https://www.thesprucepets.com/thmb/3ABKoAPm0Hu4PcWsDH1giawq7ck=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/chinese-dog-breeds-4797219-hero-2a1e9c5ed2c54d00aef75b05c5db399c.jpg"
                      alt=""
                      className="w-full h-auto object-cover rounded-md"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
