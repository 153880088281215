import serviceimg1 from "../../raitagyana-images/1DCD5CA3-CB95-4A4F-9BBE-6EFD7F085633.jpeg";
import serviceimg2 from "../../raitagyana-images/332C08AC-D008-4014-9E7E-3FC72F1F2FCA.jpeg";
import serviceimg3 from "../../raitagyana-images/II-54.webp";
import serviceimg4 from "../../raitagyana-images/4dca926b-a268-4003-9ab9-4b217ed40095.jpeg";
import serviceimg5 from "../../raitagyana-images/444D2490-FADD-4CE1-B82A-3665958A352B.jpeg";
import serviceimg6 from "../../raitagyana-images/2DC70582-8373-4231-BBD7-1096FE4F10D4.jpeg";
import serviceimg7 from "../../raitagyana-images/3BA4F670-3592-4BC8-9DEF-369FA2F64F27.jpeg";

export const serviceData = [
    {
      imageUrl: serviceimg1,
      altText: 'Quality Agricultural Inputs Supply',
      linkText: 'Quality Agricultural Inputs Supply',
    },
    {
      imageUrl: serviceimg2,
      altText: 'Sustainable Farming Training Programs',
      linkText: 'Sustainable Farming Training Programs',
    },
    {
      imageUrl: serviceimg3,
      altText: 'Storage Solutions for Agricultural Produce',
      linkText: 'Storage Solutions for Agricultural Produce',
    },
    {
      imageUrl: serviceimg4,
      altText: 'Facilitating Farmer-Market Connections',
      linkText: 'Facilitating Farmer-Market Connections',
    },
    {
      imageUrl: serviceimg5,
      altText: 'Environmentally Friendly Farming Practices',
      linkText: 'Environmentally Friendly Farming Practices',
    },
    {
      imageUrl: serviceimg6,
      altText: 'Economic Empowerment Initiatives',
      linkText: 'Economic Empowerment Initiatives',
    },
    {
      imageUrl: serviceimg7,
      altText: 'Creating Resilient Rural Communities',
      linkText: 'Creating Resilient Rural Communities',
    },
  ];