import React, { useState, useEffect } from "react";
import { Autoplay } from "swiper/modules";
import { useLocation } from "react-router-dom";
import { imageUrls } from "./aboutdata";
import { Swiper, SwiperSlide } from "swiper/react";
import "./AboutUsPage.css";
import secImg from "../../images/TriasseaIcon.png";
const AboutusBottom = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlidesPerView = () => {
    if (screenWidth <= 360) {
      return 3; // For mobile screens
    } else if (screenWidth <= 540) {
      return 4; // For tablet screens
    } else {
      return 6; // For laptop screens
    }
  };
  return (
    <section className="mt-20 main-aboutus">
      <div className="flex justify-center ml-6 items-center gap-2">
        <img className="w-8" src={secImg} alt="" />
        <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
          Our Trusted Partners
        </h3>
      </div>

      <div className="bg-orange-400 mt-10 mx-auto max-w-8xl">
        <Swiper
          slidesPerView={getSlidesPerView()}
          spaceBetween={30}
          modules={[Autoplay]}
          className="About-mySwiper"
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
        >
          {imageUrls?.map((imageUrl, index) => (
            <SwiperSlide key={index} className="about-swiper-slide">
              <img
                className="transition duration-300 opacity-50 hover:opacity-100 transform"
                src={imageUrl}
                alt={`Brand ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default AboutusBottom;
