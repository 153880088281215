import React from "react";
import "./About.css";
import aboutUs1 from "../../images/about us1.png";
import aboutUs2 from "../../images/about us2.png";
import aboutUs3 from "../../images/about us3 (1).png";
import aboutUs4 from "../../images/about us4.png";
import secImg from "../../images/TriasseaIcon.png";

import mainImage1 from "../../raitagyana-images/_13E2E30B-6AC3-4BAB-8F18-40E10E1B7889.jpeg";
import mainImage2 from "../../raitagyana-images/h1-banner01.jpeg";

const About = () => {
  const dataArray = [
    {
      image: aboutUs1,
      tag: "Our Mission",
      description:
        "Empowering farmers, fostering sustainable agriculture, transforming rural communities in India.",
    },
    {
      image: aboutUs2,
      tag: "Impact Stories",
      description:
        "Real-life tales transforming small-scale farming, inspiring change and growth.",
    },
    {
      image: aboutUs3,
      tag: "Sustainable Agriculture Initiatives",
      description:
        "Revolutionizing farming, ensuring sustainability, enhancing rural livelihoods across India.",
    },
    {
      image: aboutUs4,
      tag: "Join the Movement",
      description:
        "Be part of empowering Indian farmers, driving positive change in agriculture's future.",
    },
  ];

  return (
    <section className="grid grid-cols-1 mt-16 pr-14 pl-6 gap-20 md:grid-cols-2 lg:grid-cols-2">
      <div className="">
        <div className="flex justify-start ml-6 items-center gap-2">
          <img className="w-8" src={secImg} alt="" />
          <h3 className="text-xl md:text-2xl lg:text-3xl antialiased font-sans font-semibold opacity-80">
            About Raitagyana
          </h3>
        </div>
        <h1 className="text-[24px] mb-10 ml-6 opacity-80 md:text-[40px] lg:text-[56px] font-medium mt-2 leading-[2rem] md:leading-[2.5rem] lg:leading-[3.7rem]">
          Transforming Farms & Lives
        </h1>
        <p className="text-gray-700 ml-6 mb-4 text-sm md:text-base lg:text-lg">
          At Raitagyana, we are driven by a singular mission: to empower
          marginalized farmers and foster sustainable agriculture in rural
          India. Founded in 2023, our journey began with a vision to address the
          pressing challenges faced by small-scale farmers, including poverty,
          low yields, and limited access to resources and markets.
        </p>
        <div className="px-4">
          {dataArray.map((data, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-center items-center gap-3 item-center mb-4 md:mb-8 lg:mb-10 mr-3 md:mr-6"
            >
              <img
                className="h-15 w-15 md:h-24 md:w-20 lg:h-23 lg:w-28 rounded-full bg-lime-100 hover:bg-orange-300 fill-white ml-3 md:ml-0"
                src={data.image}
                alt=""
              />
              <div className="text-center md:text-left">
                <h4 className="text-lg font-medium opacity-80 lg:text-3xl md:text-2xl cursor-pointer hover:text-orange-400">
                  {data.tag}
                </h4>
                <p className="text-sm md:text-base lg:text-lg">
                  {data.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="image-container mt-8 md:mt-0 lg:ml-20 lg:mt-20 grid grid-cols-1 md:grid-cols-2 gap-4">
        <img
          className="hover:scale-110 transition duration-500 cursor-pointer w-full md:w-auto md:max-w-1/2"
          src="https://demo2.themelexus.com/farmor/wp-content/uploads/2023/09/h1-banner03.svg"
          alt=""
        />
        <img
          className="hover:scale-110 transition duration-500 cursor-pointer w-full mt-4 md:mt-0 md:w-auto md:max-w-1/2"
          src={mainImage1}
          alt=""
        />
        <img
          className="hover:scale-110 transition duration-500 cursor-pointer w-full mt-4 md:mt-0 md:w-auto md:max-w-1/2"
          src={mainImage2}
          alt=""
        />
      </div>
    </section>
  );
};

export default About;
