import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import secImgVid from "../../images/vidImg2.jpg";
import { FaCirclePlus, FaPercent } from "react-icons/fa6";

import "./Increase.css";

const countersData = [
  { value: 42, label: "Farming Backbone" },
  { value: 18, label: "Farming Contribution" },
  { value: 40, label: "Countries" },
];
const Increase = () => {
  const [counterOn, seCounterOn] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <section
      className="mainDivInc bg-gray-100  w-[95vw] max-w-[1725px] h-[250px]"
      style={{ borderRadius: "8px" }}
    >
      <div className="flex flex-col md:flex-row justify-around items-center gap-8">
        <img src={secImgVid} alt="" width="250px" className="ml-[-4.2%]" />
        {countersData.map((item, index) => (
          <ScrollTrigger
            key={index}
            onEnter={() => seCounterOn(true)}
            onExit={() => seCounterOn(false)}
          >
            <div className="flex flex-col min-w-[190px] items-center p-4 bg-white rounded-lg shadow-md">
              <div className="flex">
                <h1 className="text-4xl text-gray-800 opacity-80 font-extrabold">
                  ~
                  {counterOn && (
                    <CountUp start={0} end={item.value} duration={3} />
                  )}
                </h1>
                {/* <FaCirclePlus className="text-yellow-400 text-4xl" /> */}
                <FaPercent className="text-gray-400 text-4xl" />
              </div>
              <p className="text-lg font-semibold text-gray-700">
                {item.label}
              </p>
            </div>
          </ScrollTrigger>
        ))}
      </div>
    </section>
  );
};

export default Increase;
