import React, { useEffect, useRef, useState } from "react";

import logo from "../../images/logonew.png";
import "./Navbar.css";
import { RiMenu2Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menu, setMenu] = useState(false);
  const [More, setMore] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 30);
    setMore(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const toggleMenuBar = () => {
    setMenu(!menu);
  };
  //done by me
  const hideNavItems = () => {
    setMenu(false);
  };

  return (
    <div
      className={`w-[100%] scrolled max-w-[1800px] h-[80px] responsive-header pl-2 pr-20 
      ${menu ? "bg-white max-h-max" : ""} fixed top-0 z-20`}
    >
      <header className="h-full w-full box-border flex justify-between items-center">
        <div className="flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {}}
              src={logo}
              alt=""
              className={`${
                isMobile ? "w-[40px] h-[40px]" : "w-[50px] h-[50px] logo-img"
              }  `}
            />
            <span
              className={`${
                isMobile
                  ? "text-green-600 text-2xl font-semibold"
                  : "text-green-600 text-3xl font-semibold"
              }`}
            >
              AITAGYANA
            </span>
          </div>
          {isMobile ? (
            ""
          ) : (
            <p className="text-[12px] pl-10 opacity-80 font-semibold">
              Transforming Farms,Transforming Lives...
            </p>
          )}
        </div>
        {isMobile && menu && (
          <RxCross2
            cursor="pointer"
            color="black"
            size={25}
            onClick={toggleMenuBar}
          />
        )}
        {isMobile && !menu && (
          <RiMenu2Fill
            cursor="pointer"
            size={25}
            color={isScrolled ? "black" : "black"}
            onClick={toggleMenuBar}
          />
        )}

        <ul
          onClick={hideNavItems}
          className={
            isMobile
              ? `flex flex-col gap-2 nav-bg py-3 w-full  absolute h-max top-[50px] ${
                  !menu
                    ? "-right-[100%] "
                    : "right-0 transition-all .5s ease-in"
                } items-center  text-lg cursor-pointer scrolled`
              : `flex justify-between items-center gap-10 nav-text text-black`
          }
        >
          <Link to="/" className="nav-item font-semibold opacity-[85%]">
            Home
          </Link>

          <Link to="/aboutus" className="nav-item font-semibold opacity-[85%]">
            About us
          </Link>
          <Link
            to="/successstories"
            className="nav-item font-semibold opacity-[85%]"
          >
            Impact
          </Link>
          <Link to="/gallery" className="nav-item font-semibold opacity-[85%]">
            Gallery
          </Link>

          <Link
            to="/contactUs"
            className="nav-item font-semibold opacity-[85%]"
          >
            Contact us
          </Link>
        </ul>
      </header>
    </div>
  );
};

export default Navbar;
